import React, { Component } from "react";
//import Helmet from "react-helmet";
//import Layout from "../layout";
import Layout from "../components/semantic/Layout"
import {Header, Container, Button, Form} from "semantic-ui-react";
import axios from 'axios';
import ThankYouMsg from '../components/semantic/ThankYouPage'

const style = {
  h1: {
    marginTop: '3em',
    margin: '4em 0em 2em',
  },
}

class ContactUsForm extends Component {

  state = {
    name:"",
    email:"",
    location:"",
    company_type:"",
    comments:"",
    step:1,
    }

    renderLogic()
    {
      switch (this.state.step) {
        case 1:
          return this.renderForm()
      
        default: return <ThankYouMsg />
          
      }
    }

    renderForm(){
      return (
        <>
                <Header as='h2' content='Thank you for your interest in Kvantum. Please provide us a bit more information so that we can reach out to you.' style={style.h1} textAlign='center' />
        <Container>
          <Form onSubmit={this.handleSubmit} name='contact'>
            <Form.Group widths='equal'>
                <Form.Input 
                  fluid 
                  name='name'
                  label='Name'
                  placeholder='Name' 
                  value={this.state.name} 
                  onChange={this.handleInputChange}
                required />
                <Form.Input 
                  fluid 
                  name = 'email'
                  label='Email' 
                  placeholder='Email' 
                  value={this.state.email} 
                  onChange={this.handleInputChange}
                  required />
              </Form.Group>
              <Form.Group widths='equal'>
              <Form.Input 
                fluid 
                name = 'location'
                label='Location' 
                placeholder='e.g. Dallas, TX' 
                value={this.state.location}
                onChange={this.handleInputChange}
                required />
              <Form.Input 
                fluid 
                name = 'company_type'
                label='What type of company do you represent?' 
                placeholder='e.g. Agency/Brand' 
                value={this.state.company_type} 
                onChange={this.handleInputChange}
                required />
            </Form.Group>
            <Form.TextArea 
              fluid 
              name="comments"
              label='Comments' 
              placeholder='Please provide us more information on your use case.' 
              value={this.state.comments} 
              onChange={this.handleInputChange}
              required />
            <Button type="submit">Submit</Button>
          </Form>
        </Container>
</>
      )
    }

    resetForm = () => {
      this.setState({
        name:"",
        email:"",
        location:"",
        company_type:"",
        comments:"",
      })

    }

    handleInputChange = event => {
      const target = event.target
      const value = target.value
      const name = target.name
      this.setState({
        [name]: value,
      })
    }
    handleSubmit = event => {
      event.preventDefault()
      //alert(`Welcome to ${this.state.name} ${this.state.email} ${this.state.location} ${this.state.company_type} ${this.state.comments}!`)
      
      const post_url='https://api.kvantum.ai/contactUs?name='+(this.state.name)+'&email='+(this.state.email)+'&location='+(this.state.location)+'&company_type='+(this.state.company_type)+'&comments='+this.state.comments
      this.setState({step:2})

      axios.post(post_url, {
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

  render() {
    return (
      <Layout>
        <div>
          {this.renderLogic()}
        <div style={{paddingBottom:'100px'}}></div>
      </div>
      </Layout>
    );
  }
}

export default ContactUsForm;
