import React, { Component } from 'react'
import {
 
  Grid,
  Header,
  Segment,

} from 'semantic-ui-react'
import BackgroundImage from '../../images/header.png'

import 'semantic-ui-css/semantic.min.css';

//import HomepageHeading from './HomePageHeading';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.

class ThankYouMsg extends Component {
      
    render() {
      return (
        <>
            <Segment
              textAlign='center'
              vertical
              style={{ minHeight: 235, padding: '0em 0em', backgroundColor:'#000000' }}
            >
               <div style={{minHeight:'235px',  textAlign:'center', backgroundImage: "url(" + BackgroundImage + ")"}}>
                <div style={{color: '#FFFFFF', padding: '4em 0em'}}>
                  <p style={{fontSize: '4em' }}>Thank You!</p>
                  <p style={{fontSize: '1.5em' }}>We have received your request and will be in touch shortly.</p>
                </div>
               </div>
            </Segment>
            <Segment
            textAlign='center'
            vertical
            style={{  padding: '0em 0em', backgroundColor:'#ffffff' }}>
              <div style={{padding: '2em 0em', fontSize: '2em', backgroundColor:'#ffffff'}}>
            In the meantime, check out more Kvantum insights and best practices in marketing performance measurement.
            </div>
            </Segment>
            <Segment style={{ padding: '0em' }} vertical>
              <Grid stackable centered columns={3} >
                <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                    KVANTUM BLOG
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>Tap into industry news, expert insights, and practitioner perspective. Our blog is always up-to-date with the latest in marketing measurement around the world.</p>
                    <p style={{ fontSize: '1.33em' }}><a href="https://blog.kvantum.ai"  style={{color:"#b71d23"}}>READ BLOG</a></p>
                  </Grid.Column>  
                  <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                    RESOURCES
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>Check out our latest reports, white papers, guides, and more. These free digital marketing education resources are chock full of best practices for attribution, analytics, acquisition, and retention.</p>
                    <p style={{ fontSize: '1.33em' }}><a href="https://www.kvantum.ai/Resources/" style={{color:"#b71d23"}}>VIEW RESOURCES</a></p>
                  </Grid.Column>
                </Grid.Row>
      </Grid>
    </Segment>

        </>
      )
    }
  }
  

  export default ThankYouMsg